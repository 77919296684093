<template>
    <div>
        <Transition
            mode="out-in"
            leave-active-class="animated fadeOut u-anim-dur-200"
            enter-active-class="animated fadeIn u-anim-dur-200">
            <div
                v-if="hasOnlyOneMediaItem"
                key="product-details-single-media-item"
                class="relative flex flex-wrap gap-16 bg-sand-15">
                <SplitTest v-if="showFavoritedByBadge"
                           feature-key="fsst24-pdp-favorite-label"
                           default-variation="favorite-label-on">
                    <FavoritesCountBadge
                        :favorited-by="details.favoritedBy"
                        :favorite-id="selectedVariant.sku"/>
                </SplitTest>
                <div class="mx-auto w-full max-w-600" @click="handleImageClick(0)">
                    <Transition
                        mode="out-in"
                        leave-active-class="animated fadeOut u-anim-dur-200"
                        enter-active-class="animated fadeIn u-anim-dur-200">
                        <ResponsiveImage
                            :key="selectedVariant.sku"
                            class="u-image-zoom h-auto"
                            :width-on-screen="{ md: 33, lg: 90 }"
                            :aspect-ratio="600 / 800"
                            :image-url="selectedVariant.sortedMedia[0].url"
                            format="webp"
                            :quality="80"
                            bg-color="#faf7f0"
                            load-type="eager"
                            width="600"
                            height="800"/>
                    </Transition>
                </div>
            </div>
            <div v-else 
                 :key="selectedVariant.sku"
                 class="flex flex-wrap gap-16">
                <div v-for="(media, i) in mediaToShow" :key="selectedVariant.sku + i" class="media-item relative">
                    <div class="cursor-pointer">
                        <ProductDetailCarouselSlide
                            :key="media.url"
                            :details="details"
                            :selected-variant="selectedVariant"
                            :enable-favorited-by-badge="true"
                            :is-video="isVideo(media)"
                            :index="i"
                            :media="media"
                            format="webp"
                            :quality="80"
                            :height="604"
                            :width="453"
                            :width-on-screen="{ md: 33 }"
                            class="bg-sand-15"/>
                    </div>
                </div>
            </div>
        </Transition>

        <button
            v-if="shouldShowMore"
            key="showMorewMediaItems"
            class="mx-auto mb-40 mt-40 w-full font-brandon-medium uppercase leading-14 text-brown-80 underline hover:no-underline"
            @click="shouldShowRestOfMedia = true">
            {{ $translate('generic.ShowAdditional') }}
        </button>

        <ProductDetailsFullscreenImages
            :key="selectedVariant.sku"
            :details="details"
            :selected-variant="selectedVariant"
            :active-index="activeIndex"/>
    </div>
</template>

<script setup lang="ts">
import bus from '@/core/bus';
import { Media, MediaType, v4 } from '@/types/serverContract';
import { computed, defineProps, ref } from 'vue';
import { PDP_IMAGE_FULLSCREEN } from '../productHelper.utils';
import ProductDetailsFullscreenImages from '@/project/product/details/ProductDetailsFullscreenImages.vue';
import ProductDetailCarouselSlide from '@/project/product/details/ProductDetailCarouselSlide.vue';
import FavoritesCountBadge from '@/project/product/details/FavoritesCountBadge.vue';
import SplitTest from '@/project/growthbook/SplitTest.vue';

const props = defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
}>();

const activeIndex = ref(0);
const shouldShowRestOfMedia = ref(false);
const maxNumberOfMediaItemsToShowOnInit = ref(6);

const showFavoritedByBadge = computed(
    () => 'favoritedBy' in props.details && typeof props.details.favoritedBy === 'number'
);

const handleImageClick = (i) => {
    activeIndex.value = i;
    bus.emit(PDP_IMAGE_FULLSCREEN, activeIndex.value);
};

const shouldShowMore = computed(() => {
    return (
        props.selectedVariant.sortedMedia.length > maxNumberOfMediaItemsToShowOnInit.value &&
        !shouldShowRestOfMedia.value
    );
});

const mediaToShow = computed<Media[]>(() => {
    return shouldShowMore.value
        ? props.selectedVariant.sortedMedia.slice(0, maxNumberOfMediaItemsToShowOnInit.value)
        : props.selectedVariant.sortedMedia;
});

const isVideo = (media: Media) => {
    return media.type === MediaType.Video;
};

const hasOnlyOneMediaItem = computed(() => {
    return props.selectedVariant.sortedMedia.length === 1;
});
</script>

<style lang="less" scoped>
.media-item {
    width: calc(50% - 1.6rem);
}
</style>
