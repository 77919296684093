<template>
    <div>
        <button v-prohibit-zoom class=" bg-sand-20 w-24 h-24 rounded-full flex justify-center items-center"
                :aria-label="$translate('checkout.Basket.Line.QuantityMinus')"
                :title="$translate('checkout.Basket.Line.QuantityMinus')"
                @click="subtractQuantity">
            <cIcon name="minus" width="10" class="relative"/>
        </button>
        <TextInputNoValidation v-if="allowEdit"
                               v-prohibit-zoom
                               :value="localQuantity"
                               :name="`quantity-${variant.sku}`"
                               :max="
                                   inventoryMaxQuantity && inventoryMaxQuantity > 0 ? inventoryMaxQuantity : null
                               "
                               class="w-24 inline-block "
                               type="number"
                               step="1"
                               pattern="\d*"
                               custom-classes="border-0 text-center px-0"
                               input-classes="text-center p-0 font-gibson-regular text-14 leading-18"
                               :show-clear="false"
                               :select-text-on-focus="true"
                               @input="onQuantityUpdate"/>
        <span v-else class="font-gibson-regular text-14 leading-18 text-center min-w-24">
            {{ localQuantity }}
        </span>
        <button v-prohibit-zoom class=" bg-sand-20 w-24 h-24 rounded-full flex justify-center items-center"
                :aria-label="$translate('checkout.Basket.Line.QuantityPlus')"
                :title="$translate('checkout.Basket.Line.QuantityPlus')"
                :disabled="plusButtonDisabled"
                @click="addQuantity">
            <c-icon name="plus" height="10" width="10" class="relative"/>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { v4 } from '@/types/serverContract';
import { defineProps } from 'vue';
import useBasket from '@/project/product/useBasket';

const props = defineProps<{
    product: v4.Products.ProductDetails | v4.Products.ProductSimple;
    variant: v4.Products.ProductDetailsVariant | v4. Products.VariantSimple;
    inventory?: v4.Products.Inventory;
    allowEdit: boolean;
    shouldUsePackageQuantity?: boolean
}>();

const {
    localQuantity,
    onQuantityUpdate,
    plusButtonDisabled,
    inventoryMaxQuantity,
    addQuantity,
    subtractQuantity,
} = useBasket(props.product, props.variant, props.inventory, props.shouldUsePackageQuantity);

</script>