<template>
    <section>
        <breadcrumb v-if="!content.hideBreadcrumbPath"/>
        <BlocksContainer :blocks="content && content.blocks"/>
        <div class="o-container">
            <SearchProductControl v-slot="{facets, activeSortByKey, products, totalCount}"
                                  :term="searchTerm"
                                  :category-id="categoryId"
                                  :brand-id="brandId"
                                  :page-size="pageSize"
                                  :cross-category="content.crossCategory"
                                  :tracking-page-type="trackingPageType"
                                  @searching="searching">
                <div v-if="shouldShowProductList(products)">
                    <article v-if="hasContent" class="flex flex-col items-center pt-18"
                             :class="{'pb-25 md:pt-40 md:pb-40': isTopCategory, 'pb-32 md:pt-40 md:pb-40': !isTopCategory}">
                        <div v-if="content.image && !isTopCategory" class="relative z-0 -mt-6 md:mt-8 px-20 ls:px-40 md:px-60 c-header__icon text-center mb-4 md:mb-10">
                            <ResponsiveIconWrap
                                :image="content.image"
                                :alt-text="content.title"
                                class="max-w-120 lg:max-w-100"/>
                        </div>
                        <h1 v-if="content.title"
                            class="mt-5 md:mt-4 mb-0 uppercase font-brandon text-center">
                            <UmbracoText class="block font-normal leading-title text-30" :text="content.title"/>
                        </h1>
                    </article>
                    <ShowSubCategories :categories="subCategories" :show-images="isTopCategory"/>
                    <div
                        ref="filtersScrollPos"
                        :class="{ 'mt-60 md:mt-73': content.image, 'mt-55 md:mt-65': !content.image }"/>
                    <div class="category-page-filters sticky z-filters top-0 h-52 xl:h-60 -mt-20">
                        <div class="bg-white-100 h-full o-grid items-end">
                            <span class="o-grid-item grid-w-2__only-desktop md:text-right text-left uppercase text-12 font-brandon font-normal">
                                {{ $translate('search.Overlay.TotalProductResultsHeading', totalCount) }}
                            </span>
                            <span class="o-grid-item grid-w-2__only-desktop">
                                <button class="flex items-center ml-auto md:ml-0 md:mr-auto"
                                        :aria-label="$translate('search.Filters.ShowMoreFilters')"
                                        @click="facetOverlayActive = FacetOverlayModes.otherFacets">
                                    <c-icon name="filter" class="w-16"/>
                                    <span class="pl-5 uppercase text-12 font-brandon font-normal">
                                        <span class="md:hidden">
                                            {{ $translate('search.Filters.ShowMoreFilters') }}

                                        </span>
                                        <span class="hidden md:inline">
                                            {{ $translate('search.Filters.ShowMoreFiltersMinor') }}
                                        </span>
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>

                    <ShowChosenFacets v-if="facets.length" :facet-groups="facets"/>

                    <div class="o-grid c-product-list">
                        <template v-if="products.length">
                            <ProductTile v-for="(product, productIndex) in products"
                                         :key="product.id"
                                         :product="product"
                                         :ignore-inventory="false"
                                         :image-alt="[getProductTilePostfix, product.name, product.subtitle].join(' - ')"
                                         class="o-grid-item"
                                         :style="{order:(productIndex + 1)}"
                                         :data-legacy-sort="(productIndex + 1)"
                                         :tracking-index="productIndex"
                                         :tracking-list-provider="'Catalog'"/>
                        </template>

                        <template v-if="shouldShowSkeleton">
                            <ProductSkeleton v-for="(skeleton, skeletonIx) in skeletonArray" :key="'skeleton_' + skeletonIx" class="o-grid-item"/>
                        </template>

                        <template v-if="isNotFiltered(facets) && products.length">
                            <BlockRender v-for="(enrichment, enrichmentIndex) in enrichmentsList(products)"
                                         :key="`${enrichmentIndex}-${enrichment.content.position || 'x'}`"
                                         :class="getClass(enrichment.content)"
                                         class="o-grid-item h-full"
                                         :style="{order:getStyle(enrichment.content.position)}"
                                         :data-legacy-sort="getStyle(enrichment.content.position)"
                                         :block="enrichment"
                                         :tracking-position="enrichmentIndex"/>
                        </template>
                    </div>
                    <div v-if="!isSearching && !products.length" class="text-center bg-white-300 p-20 md:p-40" :class="{'mb-40': isMultiCategory}">
                        <div>
                            <ResponsiveIcon class="mb-20 md:mb-40"
                                            bg-color="f9f7f0"
                                            image-url="/images/empty-search.png" :width="160"
                                            :height="160" :alt-text="$translate('category.NoProducts')"/>
                        </div>
                        <span class="font-brandon uppercase">{{ $translate('category.NoProducts') }}</span>
                    </div>

                    <div v-if="products && totalCount > products.length" class="flex" :class="{'mb-40': isMultiCategory}">
                        <NextPageLink class="mt-40 inline-flex mx-auto"
                                      item-class="c-btn c-btn--primary"
                                      :is-searching="isSearching"
                                      :aria-label="$translate('search.Overlay.Products.ViewMoreProductResults')">
                            <span class="c-btn__text">{{ $translate('search.Overlay.Products.ViewMoreProductResults') }}</span>
                        </NextPageLink>
                    </div>

                    <PortalOverlay :show.sync="facetOverlayActiveInternally"
                                   wrapper-class="ls:max-w-400 ml-auto"
                                   :disable-body-scroll="false"
                                   side="right">
                        <div class="">
                            <div class="bg-white-100 min-h-screen px-20">
                                <FacetControls :facets="facets"
                                               :active-sort-by-key="activeSortByKey"/>
                            </div>
                            <footer slot="footer" class="sticky bottom-0 left-0 right-0 z-5 bg-white-100 p-20 flex shadow-modal-footer">
                                <button class="c-btn c-btn--primary-outlined w-1/2 mr-5" @click="clearAllFacets">
                                    <span class="c-btn__text font-light w-full">
                                        {{ $translate('search.Filters.ClearAll') }}
                                    </span>
                                </button>
                                <button class="c-btn c-btn--primary w-1/2 ml-5" @click="facetOverlayActive = FacetOverlayModes.none">
                                    <span class="c-btn__text font-light w-full">{{ $translate('search.Filters.MobileCloseFilters', totalCount) }}</span>
                                </button>
                            </footer>
                        </div>
                    </PortalOverlay>
                </div>
            </SearchProductControl>

            <footer v-if="!brandId && !isMultiCategory" class="category-page-next-category-trigger relative py-30 md:py-60">
                <div class="mb-30 text-center">
                    <span class="inline-block w-1 mx-auto bg-brown-80 h-100 opacity-25"/>
                </div>
                <header class="text-center uppercase font-brandon font-normal mb-60">
                    <span class="block text-10 leading-loose mb-5">{{ $translate('category.NextCategory.Supplementary') }}</span>
                    <UmbracoText class="block text-30 leading-sm" :text="content?.nextCategoryNavigationTitle"/>
                </header>
                <div class="c-next-category-wrapper relative flex flex-wrap -mx-10 md:-mx-15">
                    <article v-for="(nextProduct, nextProductIndex) in nextCategoryProducts" :key="nextProduct.id" class="w-1/2 px-10 mb-20 md:mb-60 md:w-1/4 md:px-15">
                        <ProductTile :product="nextProduct"
                                     :enable-tracking="false"
                                     :ignore-inventory="true"
                                     :tracking-index="nextProductIndex"
                                     :tracking-list-provider="'Catalog'"
                                     tracking-list-type="NextCategory"/>
                    </article>
                    <template v-if="!nextCategoryProducts?.length">
                        <ProductSkeleton v-for="(skeleton, skeletonIx) in 4" :key="'skeleton_next_' + skeletonIx" class="w-1/2 px-10 mb-20 md:mb-60 md:w-1/4 md:px-15"/>
                    </template>
                    <RouterLink v-if="content.nextCategoryUrl" :to="content.nextCategoryUrl" :title="$translate('category.NextCategory.LinkTitle')" class="absolute inset-0 z-3"/>
                </div>
                <div v-if="content?.nextCategoryUrl" class="absolute bottom-0 w-full pb-40 text-center z-3 md:pb-80">
                    <RouterLink :to="content?.nextCategoryUrl" :title="$translate('category.NextCategory.LinkTitle')" class="c-btn c-btn--primary c-btn--sm inline-flex">
                        <span class="c-btn__text text-10 md:text-14">
                            {{ $translate('category.NextCategory.LinkTitle') }}
                        </span>
                    </RouterLink>
                </div>
            </footer>
        </div>
        <BlocksContainer :blocks="content && content.blocksBelow" :class="{'category-page-next-category-trigger': isMultiCategory}"/>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CategoryDetailsContentModel, FacetGroup, FeaturedProductBlockModel, JsonContent, NavigationLinkItem, UspBlockModel, ProductSearchResultNew, v4, BrandPageContentModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import range from 'lodash-es/range';
import { styleFunctionsForThemedContent } from '@/project/shared/styleFunctionsForThemedContent';
import urlHelper from '@/project/facets/urlHelper.service';

import SearchProductControl from '@/project/search/SearchProductControl.vue';
import SearchSorting from '@/project/search/SearchSorting.vue';
import FacetControls from '@/project/search/FacetControls.vue';
import DropdownMultiFacet from '@/project/facets/DropdownMultiFacet.vue';
import MobileFacetContainer from '@/project/facets/MobileFacetContainer.vue';
import ShowChosenFacets from '@/project/facets/ShowChosenFacets.vue';
import Breadcrumb from '@/project/shared/navigation/Breadcrumb.vue';
import SearchFilterButtons from '@/project/search/SearchFilterButtons.vue';
import ProductSkeleton from '@/project/product/ProductSkeleton.vue';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import NextPageLink from '@/project/search/NextPageLink.vue';
import spaStore from '@/core/spa/store/spa.store';
import { doDataLayerPush, getExistingFacetsForTracking } from '@/project/tracking/googleTagManager.utils';
import ShowSubCategories from '@/project/spa/pages/categoryPage/ShowSubCategories.vue';
import scrollService from '@/core/scroll/scroll.service';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import bus from '@/core/bus';
import { PageHeaderResetScrollBehaviour, PageHeaderStopScrollBehaviour, PageHeaderToggleHidden, PageHeaderToggleShowAtNextCategory } from '@/project/config/constants';

export enum FacetOverlayModes {
    'none' = 0,
    'otherFacets'
}
@Component({
    name: 'CategoryDetailsPage',
    components: {
        ShowSubCategories,
        NextPageLink,
        SearchFilterButtons,
        Breadcrumb,
        SearchSorting,
        DropdownMultiFacet,
        MobileFacetContainer,
        SearchProductControl,
        ShowChosenFacets,
        ProductSkeleton,
        ResponsiveIcon,
        FacetControls
    }
})
export default class CategoryDetailsPage extends Vue {
    searchTerm = '';
    nextCategorySearchResult: ProductSearchResultNew | null = null;
    nextCategoryLookupTimeout: any;
    isSearching: boolean = true;
    skeletonArray = range(8);
    facetOverlayActiveInternally: boolean = false;
    facetOverlayActive: FacetOverlayModes = FacetOverlayModes.none;
    pageSize: number = 48;
    _scrollTrigger: ScrollTrigger | undefined;

    @Prop() content!: CategoryDetailsContentModel | BrandPageContentModel;

    get shouldShowSkeleton(): boolean {
        const hasPaging: string | undefined = urlHelper.getQueryParam('page');
        // Show skeleton if searching and we are not on pagenized page;
        return this.isSearching && !hasPaging;
    }

    mounted() {
        bus.emit(PageHeaderStopScrollBehaviour);
        const filters = document.querySelector('.category-page-filters');

        if (!filters) return;

        this._scrollTrigger = ScrollTrigger.create({
            trigger: '.category-page-filters',
            endTrigger: '.category-page-next-category-trigger',
            start: 'top-=50px top',
            end: 'top top',
            invalidateOnRefresh: true,
            onEnter: () => {
                bus.emit(PageHeaderToggleHidden, true);
                filters.classList.add('border-b');
                filters.classList.add('border-white-200');
            },
            onEnterBack: () => {
                bus.emit(PageHeaderToggleShowAtNextCategory, false);
            },
            onLeave: () => {
                bus.emit(PageHeaderToggleShowAtNextCategory, true);
            },
            onLeaveBack: () => {
                bus.emit(PageHeaderToggleHidden, false);
                filters.classList.remove('border-b');
                filters.classList.remove('border-white-200');
            }
        });
    }

    destroyed() {
        if (this.nextCategoryLookupTimeout) {
            clearTimeout(this.nextCategoryLookupTimeout);
        }

        if (this._scrollTrigger) {
            // Kill the instance so it doesn't overwrite the rest of the site.
            // eslint-disable-next-line no-unused-expressions
            this._scrollTrigger.kill();
        }

        bus.emit(PageHeaderResetScrollBehaviour);
    }

    get categoryId(): string {
        return this.content ? this.content.categoryId : '';
    }

    get brandId(): string {
        return this.content ? this.content.brandId : '';
    }

    get isMultiCategory(): boolean {
        return this.content ? !!this.content.crossCategory : false;
    }

    get hasContent(): boolean {
        return !!this.content?.image || !!this.content?.title;
    }

    get isTopCategory(): boolean {
        return this.content.isTopCategory;
    }

    get trackingPageType(): string {
        if (!this.content) {
            return '';
        }
        if (this.content.crossCategory === 'news') {
            return 'newsListPage';
        }
        if (['campaign', 'brand'].includes(this.content.crossCategory)) {
            return 'campaignListPage';
        }
        // eventually add additional magic strings here for "all products" etc.
        return 'categoryListPage';
    }

    searching(isSearching: boolean) {
        this.isSearching = isSearching;
        if (!isSearching) {
            this.$nextTick(() => {
                const hasScrolled = document.documentElement.scrollTop || window.scrollY;
                if (this.facetOverlayActive === FacetOverlayModes.otherFacets && hasScrolled) {
                    scrollService.scrollToElement(this.$refs.filtersScrollPos as HTMLElement);
                }
            });
        }
    }

    @Watch('facetOverlayActive')
    onFacetOverlayActiveChange(newState: FacetOverlayModes) {
        this.facetOverlayActiveInternally = newState !== FacetOverlayModes.none;
    }

    @Watch('facetOverlayActiveInternally')
    onFacetOverlayActiveInternallyChange(newState: boolean) {
        if (!newState) {
            this.facetOverlayActive = FacetOverlayModes.none;
        }
    }

    @Watch('categoryId', {
        immediate: true
    })
    onCategoryIdUpdate() {
        if (this.isMultiCategory) {
            return;
        }
        if (this.nextCategoryLookupTimeout) {
            clearTimeout(this.nextCategoryLookupTimeout);
        }
        this.nextCategorySearchResult = null;
        this.nextCategoryLookupTimeout = setTimeout(this.loadNextCategory, 2000);
    }

    get subCategories(): NavigationLinkItem[] {
        return this.content.subCategories?.length ? this.content.subCategories : (this.content.siblingsAndSelfCategories ?? []);
    }

    async loadNextCategory() {
        if (this.content.nextCategoryId) {
            this.nextCategorySearchResult = await Api.catalog.simpleCategorySearch(this.content.nextCategoryId, 4);
        }
    }

    get nextCategoryProducts(): v4.Products.ProductSimple[] {
        return this.nextCategorySearchResult ? this.nextCategorySearchResult.searchResultItems : [];
    }

    get FacetOverlayModes() {
        return FacetOverlayModes;
    }

    isNotFiltered(facets: FacetGroup[]): boolean {
        // using real facets, not query parameters, as umbraco preview will add language, facebook will add fbcid etc.
        return !(facets && (facets.find(f => f?.values?.find(v => v.selected === true)) || urlHelper.getQueryParam('unitPriceInclVat')));
    }

    getStyle(position: number) {
        return position || 0;
    }

    enrichmentsList(products: v4.Products.ProductSimple[]): JsonContent[] {
        if (products.length === 0) {
            return [];
        }

        return (this.content.enrichments.filter(enrichment => (enrichment.content as FeaturedProductBlockModel | UspBlockModel).position <= products.length)) as JsonContent[];
    }

    shouldShowProductList(products: v4.Products.ProductSimple[]): boolean {
        return !this.brandId || products.length > 0;
    }

    getClass(enrichment: FeaturedProductBlockModel) : string[] {
        if (!enrichment) {
            return [];
        }

        const classes = [styleFunctionsForThemedContent(enrichment.textColor)];

        if (enrichment.size) {
            classes.push(`grid-w-${enrichment.size.cols}`);
            classes.push(`grid-h-${enrichment.size.rows}`);
        }

        return classes;
    }

    nextPage(e:Event) {
        e && e.preventDefault();
        if (!this.isSearching) {
            urlHelper.setPage(urlHelper.getPage() + 1);
        }
    }

    get getProductTilePostfix(): string {
        return (spaStore.metadata && spaStore.metadata.navigationTitle) || '';
    }

    clearAllFacets(): void {
        const eventLabel = getExistingFacetsForTracking();
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Facet',
            eventAction: 'Remove all facets',
            eventLabel: eventLabel,
            eventValue: undefined
        });
        urlHelper.clearFacets();
    }
}
</script>

<style lang="less" scoped>
.c-next-category-wrapper {
    &::after {
        @apply absolute inset-0 z-2;
        content: '';
        background-image: linear-gradient(0deg, rgba(255,253,245,1) 20%, rgba(229,237,231,0) 100%);
    }
}
</style>
