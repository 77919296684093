<template>
    <LineItemWrapper v-slot="{ getImage, variantUrl }"
                     :line-item="line">
        <article class="py-16 flex" :class="{'opacity-0 u-trans': showLine === false}">
            <div ref="productImage" class="w-96 md:w-120 flex-shrink-0">
                <RouterLink :to="variantUrl" class="relative block" :title="line.product.name">
                    <ResponsiveImage
                        :image-url="getImage.url"
                        :alt="line.product.name"
                        :class="{'c-on-error': !variant?.media}"
                        :width-on-screen="{xs:10}"
                        :aspect-ratio="96/128"
                        mode="crop"/>
                </RouterLink>
            </div>
            <div class="ml-12 relative flex-grow md:ml-16">
                <div ref="topPart" class="pb-10 md:pb-18">
                    <div class="flex justify-between">
                        <RouterLink :to="variantUrl" :title="line.product.name">
                            <UmbracoText
                                :text="line.product.name"
                                tag="div"
                                class="block font-gibson-regular text-14 md:text-16 leading-14 break-words pt-10 mb-4 md:pt-14 transition-opacity duration-2000"
                                :class="{'opacity-50': showingMovingToFavoriteMessage}"/>
                        </RouterLink>
                        <button v-if="!showingMovingToFavoriteMessage" class="c-btn c-btn--sm c-btn--clean mt-8 pl-10"
                                :aria-label="$translate('checkout.Basket.Line.Remove')"
                                :title="$translate('checkout.Basket.Line.Remove')"
                                @click="removeLineItem">
                            <c-icon class="self-center bg-transparent -top-1 relative w-15 h-16" name="trashcan"/>
                        </button>
                    </div>
                    <RouterLink :to="variantUrl" :title="line.product.name">
                        <UmbracoText
                            v-if="line.product && line.product.subtitle"
                            :text="line.product.subtitle"
                            tag="div"
                            class="block font-gibson-light text-12 leading-14 md:text-14 mb-4 break-words transition-opacity duration-2000"
                            :class="{'opacity-50': showingMovingToFavoriteMessage}"/>
                        <div
                            class="font-gibson-light text-12 leading-14 md:text-14 mb-4 break-words duration-2000"
                            :class="{'opacity-50': showingMovingToFavoriteMessage}">
                            <span v-if="hasVariant">
                                {{ line.product.variantInfo?.label }}: {{ variant?.variantName }}
                            </span>
                        </div>
                    </RouterLink>
                </div>
                <div ref="bottomPart" :class="(alignBottomPartWithImageBottom ? 'absolute bottom-12 w-full' : 'relative')">
                    <div
                        class="flex justify-between"
                        :class="{'opacity-0': showingMovingToFavoriteMessage}"
                        style="transition: opacity 0.5s">
                        <div class="font-gibson-light text-12 leading-18 md:text-14">
                            <template v-if="variant?.packageQuantity && variant?.packageQuantity > 1">
                                <SplitTest feature-key="package-quantity-test"
                                           default-variation="variation-1">
                                    {{ variant?.pricing?.unit?.formatted }}
                                </SplitTest>
                                <SplitTest feature-key="package-quantity-test"
                                           variation="variation-2"
                                           default-variation="variation-1">
                                    {{ variant?.pricing?.unit?.formatted }}
                                </SplitTest>
                                <SplitTest feature-key="package-quantity-test"
                                           variation="variation-3"
                                           default-variation="variation-1">
                                    {{ variant?.pricing?.package?.formatted }}
                                </SplitTest>
                            </template>
                            <template v-else>
                                {{ variant?.pricing?.unit?.formatted }}
                            </template>
                        </div>
                        <div class="font-gibson-regular text-14 leading-18 md:text-18">
                            <transition mode="out-in"
                                        name="fade-slide">
                                <span :key="`${line.sku}-${line.totalInclVat}`">{{ $currency(line.totalInclVat, true) }}</span>
                            </transition>
                        </div>
                    </div>
                    <div v-if="lineHasEcoCharge" class="w-full mt-6 -mb-6 font-gibson-light text-8 text-right text-brown-50">
                        {{ ecoChargeLabel }}
                    </div>
                    <div class="flex mt-10 justify-between" :class="alignBottomPartWithImageBottom ? 'md:mt-26' : 'md:mt-12'">
                        <LineItemFavourite
                            :line="line"
                            :variant-name="variantName"
                            @add-to-favourite="onAddToFavourite"
                            @moving-message-visibility-change="movingFavoriteMessageVisibilityChange"/>
                        <template v-if="variant?.packageQuantity && variant?.packageQuantity > 1">
                            <SplitTest feature-key="package-quantity-test"
                                       default-variation="variation-1">
                                <LineItemAddToCart :product="line.product" 
                                                   :variant="variant" 
                                                   :inventory="inventory"
                                                   :allow-edit="allowEdit"
                                                   class="flex items-baseline text-right"
                                                   :class="{'opacity-0': showingMovingToFavoriteMessage}"
                                                   style="transition: opacity 0.5s"/>
                            </SplitTest>
                            <SplitTest feature-key="package-quantity-test"
                                       variation="variation-2"
                                       default-variation="variation-1">
                                <LineItemAddToCart :product="line.product" 
                                                   :variant="variant" 
                                                   :inventory="inventory"
                                                   :allow-edit="false"
                                                   should-use-package-quantity
                                                   class="flex items-baseline text-right"
                                                   :class="{'opacity-0': showingMovingToFavoriteMessage}"
                                                   style="transition: opacity 0.5s"/>
                            </SplitTest>
                            <SplitTest feature-key="package-quantity-test"
                                       variation="variation-3"
                                       default-variation="variation-1">
                                <LineItemAddToCart :product="line.product" 
                                                   :variant="variant" 
                                                   :inventory="inventory"
                                                   :allow-edit="false"
                                                   should-use-package-quantity
                                                   class="flex items-baseline text-right"
                                                   :class="{'opacity-0': showingMovingToFavoriteMessage}"
                                                   style="transition: opacity 0.5s"/>
                            </SplitTest>
                        </template>
                        <template v-else>
                            <LineItemAddToCart :product="line.product" 
                                               :variant="variant" 
                                               :inventory="inventory"
                                               :allow-edit="allowEdit"
                                               class="flex items-baseline text-right"
                                               :class="{'opacity-0': showingMovingToFavoriteMessage}"
                                               style="transition: opacity 0.5s"/>
                        </template>
                    </div>
                </div>
            </div>
        </article>
    </LineItemWrapper>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { v4 } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import Api, { BasketLineWithNewProduct } from '@/project/http/api';
import debounce from 'lodash-es/debounce';
import LineItemWrapper from '@/project/checkout/LineItemWrapper.vue';
import LineItemFavourite from '@/project/checkout/LineItemFavourite.vue';
import { getSelectedVariantName, variantEcoCharge, variantHasEcoCharge, productHasVariants } from '@/project/product/productHelper.utils';
import translateFilter from '@/core/translation/translate.filter';
import currencyFilter from '@/project/shared/currency/currency.filter';
import trackingUtils from '@/project/tracking/tracking.utils';
import { ResizeObserver } from '@juggle/resize-observer';
import LineItemAddToCart from './LineItemAddToCart.vue';
import SplitTest from '@/project/growthbook/SplitTest.vue'

const FavoriteToggle = () => import('@/project/favorites/FavoriteToggle.vue');

@Component({
    components: { FavoriteToggle, LineItemWrapper, LineItemFavourite, LineItemAddToCart, SplitTest }
})
export default class LineItem extends Vue {
    @Prop() line!: BasketLineWithNewProduct;
    @Prop() inventory!: v4.Products.Inventory;
    @Prop() allowEdit!: boolean;
    quantity = 0;
    editedQuantity: string = '';
    debouncedUpdateLineItem = debounce(this.updateLineItem, 500);
    showLine: boolean = true;

    showingMovingToFavoriteMessage: boolean = false;
    resizeObserver:ResizeObserver | null = null;

    public $refs!: {
        topPart: HTMLElement,
        bottomPart: HTMLElement,
        productImage: HTMLElement,
    };

    alignBottomPartWithImageBottom: boolean = true;

    mounted() {
        this.quantity = this.line.quantity;
        this.resizeObserver = new ResizeObserver((entries, observer) => {
            this.recalculateAlignment();
        });
        this.resizeObserver.observe(this.$refs.topPart);
    }

    destroyed() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    recalculateAlignment() {
        const imageHeight = this.$refs.productImage.offsetHeight;
        const contentHeight = this.$refs.topPart.offsetHeight + this.$refs.bottomPart.offsetHeight;

        this.alignBottomPartWithImageBottom = (imageHeight - contentHeight > 10);
    }

    movingFavoriteMessageVisibilityChange(showing:boolean) {
        this.showingMovingToFavoriteMessage = showing;
    }

    get variant(): v4.Products.VariantSimple | undefined {
        if (this.line.product.variants.length) {
            return this.line.product.variants.find(v => v.sku === this.line.sku);
        } else {
            return undefined;
        }
    }

    get addButtonDisabled(): boolean {
        if (this.inventory) {
            return this.quantity < 1 || this.quantity >= this.inventory.inStock;
        }
        return true;
    }

    get maxQuantity(): number {
        return this.inventory ? this.inventory.inStock : this.quantity;
    }

    addQuantity(newQuantity:number, debounceUpdate: boolean = true) {
        let q = (this.quantity || 0) + newQuantity;
        q = q >= 0 ? q : 0; // if quantity gets below 0
        if (q === this.quantity) return; // if quantity is untouched, ignore it
        this.quantity = q;
        this.debouncedUpdateLineItem();
    }

    updateLineItem() {
        Api.basket.updateLineItem({ quantity: this.quantity, sku: this.line.sku });
        trackingUtils.basket.trackModifyCart(
            this.line.product,
            Math.abs(this.quantity - this.line.quantity),
            this.quantity - this.line.quantity > 0,
            this.line.unitPriceInclVat,
            'basket',
            this.line.sku
        );
    }

    removeLineItem() {
        Api.basket.updateLineItem({ quantity: 0, sku: this.line.sku });
        trackingUtils.basket.trackRemoveFromCart(
            this.line.product,
            this.line.quantity,
            this.line.unitPriceInclVat,
            'basket',
            this.line.sku
        );
    }

    get getTrackingEventAction(): string {
        return this.line.product.name + '_' + this.variantName;
    }

    get lineHasEcoCharge(): boolean {
        return this.variant ? variantHasEcoCharge(this.variant) : false;
    }

    get ecoChargeLabel():string {
        const amount: number = variantEcoCharge(this.variant) * this.line.quantity;
        return this.variant ? translateFilter('checkout.Basket.Line.EcoCharge', currencyFilter(amount, true)) : '';
    }

    onQuantityUpdate(quantity) {
        const parsedQuantity = parseInt(quantity, 10);
        const isMinReached = quantity < 1;
        const isMaxReached = parsedQuantity > this.maxQuantity;
        const stringedQuantity = parsedQuantity.toString();
        if (stringedQuantity === quantity) {
            if (isMinReached) {
                this.quantity = 1;
            } else if (isMaxReached) {
                this.quantity = this.maxQuantity;
            } else {
                this.quantity = parsedQuantity;
            }
            this.debouncedUpdateLineItem();
        }
    }

    onAddToFavourite() {
        this.removeLineItem();
    }

    @Watch('line.quantity')
    onServerUpdatesQuantity() {
        this.quantity = this.line.quantity;
    }

    get myPageFavoriteProductsUrl() {
        return serverContext.myPageFavoriteProductsUrl;
    }

    get labelAddFavorite(): string {
        return translateFilter('checkout.Basket.Line.MoveToFavorite', translateFilter('myPage.FavoriteProducts.Title'));
    }

    get labelRemoveFavorite(): string {
        return translateFilter('checkout.Basket.Line.RemoveFavorite', translateFilter('myPage.FavoriteProducts.Title'));
    }

    get hasVariant(): boolean {
        return productHasVariants(this.line.product);
    }

    get variantName(): string {
        if (!this.variant) return '';
        return getSelectedVariantName(this.variant);
    }

    get variantTypeLabel():string {
        const product = this?.line?.product;
        return product?.variantInfo?.label ?? '';
    }
}
</script>
