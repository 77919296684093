<template>
    <div v-if="hasCheckout" class="text-center">
        <div
            v-if="availabilityStatus === v4.Products.AvailabilityStatus.AvailableAtLaterDate"
            class="flex min-h-40 items-center justify-center rounded-2 bg-disabled-20 px-4 font-gibson-light text-14 leading-16 text-brown-80"
            v-html="soldFromLabel"></div>
        <div
            v-if="availabilityStatus === v4.Products.AvailabilityStatus.NeverAvailableOnline"
            class="flex h-40 items-center justify-center rounded-2 bg-disabled-20 px-4 font-gibson text-14 font-medium leading-16 text-brown-80">
            {{ $translate('product.AddToCart.Pdp.InStoreOnly') }}
        </div>
        <div
            v-if="availabilityStatus === v4.Products.AvailabilityStatus.NotAvailableOnline"
            class="flex h-40 items-center justify-center rounded-2 bg-disabled-20 px-4 font-gibson text-14 font-medium leading-16 text-brown-80">
            {{ $translate('product.AddToCart.Pdp.NotInStockOnline') }}
        </div>
        <div
            v-else-if="availabilityStatus === v4.Products.AvailabilityStatus.NotAvailableOnlySoldOnline"
            class="flex flex-col gap-8">
            <div
                class="flex h-40 items-center justify-center rounded-2 bg-disabled-20 px-4 font-gibson text-14 font-medium leading-16 text-brown-80">
                {{ $translate('product.AddToCart.Pdp.NotInStock') }}
            </div>
            <div class="font-gibson text-12 font-medium leading-16 text-brown-60">
                {{ $translate('product.AddToCart.Pdp.OnlineOnlyNote.Short') }}
            </div>
        </div>
        <div
            v-else-if="
                availabilityStatus === v4.Products.AvailabilityStatus.Available ||
                    availabilityStatus === v4.Products.AvailabilityStatus.AvailableOnlySoldOnline
            ">
            <div class="flex flex-col gap-4 relative">
                <transition
                    mode="out-in"
                    leave-active-class="animated fadeOut u-anim-dur-200"
                    enter-active-class="animated fadeIn u-anim-dur-200">
                    <div v-if="selectedVariant && localQuantity && localQuantity > 0" key="in-basket">
                        <div class="c-add-to-cart-input flex h-40 gap-8">
                            <button
                                v-prohibit-zoom
                                class="focus-styes flex flex-shrink-0 items-center justify-center rounded-2 w-40 bg-brown-80 text-sand-10 hover:bg-brown-90"
                                :aria-label="$translate('product.AddToCart.RemoveOneFromBasket', product.name)"
                                @click="subtractQuantity">
                                <c-icon name="minus" width="16"/>
                            </button>
                            <div class="flex-grow flex w-80 items-stretch">
                                <TextInputNoValidation
                                    v-prohibit-zoom
                                    :disabled="shouldUsePackageQuantity && !!selectedVariant.packageQuantity"
                                    :value="localQuantity"
                                    :name="`quantity-${selectedVariant.sku}`"
                                    type="number"
                                    step="1"
                                    :max="
                                        inventoryMaxQuantity && inventoryMaxQuantity > 0 ? inventoryMaxQuantity : null
                                    "
                                    pattern="\d*"
                                    custom-classes="flex items-stretch w-full border-sand-40 rounded-2"
                                    input-classes="p-0 rounded-2 text-center c-text-input--font-regular text-14 leading-16 text-brown-80"
                                    :aria-label="$translate('product.AddToCart')"
                                    :show-clear="false"
                                    :select-text-on-focus="true"
                                    @input="onQuantityUpdate"/>
                            </div>
                            <button
                                v-prohibit-zoom
                                class="focus-styes flex flex-shrink-0 items-center justify-center rounded-2 w-40 bg-brown-80 text-sand-10 hover:bg-brown-90"
                                :disabled="plusButtonDisabled"
                                :aria-label="$translate('product.AddToCart.AddOneMore', product.name)"
                                @click="addQuantity">
                                <c-icon name="plus" width="16"/>
                            </button>
                        </div>
                    </div>
                    <button
                        v-else-if="selectedVariant && localQuantity === 0"
                        key="not-in-basket"
                        class="focus-styes h-40 flex items-center justify-center rounded-2 bg-brown-80 px-4 font-brandon-medium uppercase leading-16 text-14 text-sand-10 hover:bg-brown-90"
                        :aria-label="ariaLabelAddToCart"
                        @click="addFirst()">
                        {{ $translate('product.AddToCart.Pdp.AddToCart') }}
                    </button>
                </transition>
                <transition mode="out-in" name="fade-slide">
                    <div
                        v-if="selectedVariant && localQuantity && localQuantity > 0 && plusButtonDisabled"
                        class="font-gibson text-12 font-medium leading-16 text-brown-60 absolute -mb-20 top-auto inset-0">
                        {{ $translate('product.AddToCart.MaxQuantityReached') }}
                    </div>
                    <div
                        v-else-if="availabilityStatus === v4.Products.AvailabilityStatus.AvailableOnlySoldOnline"
                        class="font-gibson text-12 font-medium leading-16 text-brown-60 absolute -mb-20 top-auto inset-0">
                        {{ $translate('product.AddToCart.Pdp.OnlineOnlyNote.Short') }}
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import { getMonthLabelKey, newLineToBr } from '@/project/shared/string.util';
import { v4 } from '@/types/serverContract';
import useBasket from '@/project/product/useBasket';

const props = defineProps<{
    product: v4.Products.ProductDetails | v4.Products.ProductSimple;
    selectedVariant: v4.Products.ProductDetailsVariant | v4. Products.VariantSimple;
    tileMode?: boolean | undefined;
    inventory?: v4.Products.Inventory;
    textColor?: string;
    shouldUsePackageQuantity?: boolean;
}>();

const {
    localQuantity,
    variants,
    availabilityStatus,
    onQuantityUpdate,
    plusButtonDisabled,
    inventoryMaxQuantity,
    addFirst,
    addQuantity,
    subtractQuantity,
} = useBasket(props.product, props.selectedVariant, props.inventory, props.shouldUsePackageQuantity);

const soldFromDateFormatted = computed(() => {
    if (!props.selectedVariant.availability.availableAt) return '';

    const dateObject = new Date(props.selectedVariant.availability.availableAt || Date());
    const month = dateObject.getMonth();
    const day = dateObject.getDate();

    return translateFilter(
        'generic.DateDDMMFormat',
        day.toString(),
        translateFilter(getMonthLabelKey(month))
    );
});

const soldFromLabel = computed(() => {
    return newLineToBr(translateFilter('product.AddToCart.SoldFrom.TileVariantText', soldFromDateFormatted.value));
});

const hasCheckout = computed(() => {
    return serverContext.checkoutContext.hasCheckout;
});

const ariaLabelAddToCart = computed(() => {
    return translateFilter(
        variants.value.length <= 1 ? 'product.AddToCart.AriaLabel' : 'product.AddToCart.AriaLabelWithVariant',
        props.product.name || '',
        props.selectedVariant.color?.name || ''
    );
});
</script>

<style lang="less" scoped>
.c-add-to-cart-input :deep(.c-text-input > div:first-child) {
    flex: 0 1 100%;
}
.c-add-to-cart-input :deep(.c-text-input__input-wrap) {
    flex: 0 1 100%;
}

.focus-styes {
    &:focus-visible {
        outline-color: theme('colors.brown-80');
        outline-style: auto;
        outline-width: 0.1rem;
        outline-offset: 0.5rem;
    }
}
</style>
